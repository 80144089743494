import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import SlurMachine from './components/SlurMachine';
import theme from './theme';
import ReactGA from 'react-ga';
import 'react-toastify/dist/ReactToastify.css';
import 'react-infinite-calendar/styles.css';

ReactGA.initialize('UA-139564314-1');
ReactGA.pageview(window.location.pathname);

const App = () => (
  <ThemeProvider theme={theme}>
    <SlurMachine />
  </ThemeProvider>
);

export default App;
