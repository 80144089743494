import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';


const Logo = styled('img')`
  height: ${({ theme }) => theme.plugSize};
  ${({ theme }) => css`
    @media (max-width: ${theme.mobileWidth}px) {
      height: ${theme.mobile.plugSize};
    }
  `}
  margin: 2vmin;
  :active {
    transform: scale(0.9);
  }
`;

const Plug = ({ url, img, name }) => (
  <a href={url}>
    <Logo src={img} alt={name} />
  </a>
);

export default Plug;
