import React from 'react';
import styled from '@emotion/styled';
import { Icon } from 'semantic-ui-react'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
`;

const HelpButton = styled.div`
  font-size: ${({ theme }) => theme.fontSize.help};
  line-height: ${({ theme }) => theme.fontSize.help};
  :hover {
    cursor: pointer;
  }
`;

const popupConfig = {
  position: "top-center",
  autoClose: false,
  closeOnClick: true,
  draggable: true
};

const PopupText = () => (
  <div>
    <p>Saying bad words online is fun! But getting banned isn't.</p>
    <p>This tool will translate any swear word into an innocuous word you can use instead. The replacement word will be different every day!</p>
    <p>Replacement words will all be lowercase, with a capitalized last letter. (This makes them easy to spot!)</p>
    <p>Enter your slur into the search bar and choose from one of the suggestions to see your translation for today.</p>
    <p>It goes the other way, too. Flip the switch, select a date, and input your innocuous word to see what they <i>really</i> called you.</p>
  </div>
)

const Help = ({ onClick }) => (
  <Wrapper>
    <HelpButton>
      <Icon onClick={onClick(PopupText, popupConfig)} name="question circle outline" />
    </HelpButton>
  </Wrapper >
)

export default Help;