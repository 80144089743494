import React, { useState, useEffect } from 'react';
import { Search } from 'semantic-ui-react';
import { css } from 'emotion'
import api from '../api';

const scrollable = css`
  input {
    width: 40vmin;
  }
  .results {
    max-height: 30vh;
    overflow-y: scroll;
  }
`;

const SlurSearch = ({ value, onChange, query, onSelect, searchMode }) => {
  const [slurList, setSlurList] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    api.blos().then(setSlurList)
  }, [])

  const isPlural = (slur) => {
    const singular = slur.slice(0, -1);
    const lastLetter = slur.slice(-1);
    return lastLetter === 's' && slurList.includes(singular);
  };

  const onSearch = (_, { value }) => {
    onChange(value)
    return value.length 
      ? setResults([...(isPlural(value) ? [value.slice(0, -1)] : []), ...slurList.filter(slur => slur.startsWith(value.toLowerCase()))])
      : setResults([]);
  }

  const onResult = (_, { result }) => {
    onChange(result.title)
    onSelect(result.title);
  }

  const formatResults = x => x.map(title => ({ title }))

  return (
    <Search
    fluid
      value={query}
      className={scrollable}
      results={searchMode ? formatResults(results) : [{ title: query }]}
      onSearchChange={searchMode ? onSearch : (_, { value }) => onChange(value)}
      onResultSelect={onResult}
      placeholder={value || `Enter a ${searchMode ? 'slur' : 'codeword' }.`}
      noResultsMessage={`"${query}" isn't a slur we have on file.`}
    />
  )
}

export default SlurSearch;