import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { CSSTransitionGroup } from 'react-transition-group';
import config from '../config';

const Card = styled('div')`
  text-align: center;
  margin-bottom: 2rem;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSize.title};
  line-height: ${({ theme }) => theme.fontSize.title};

  ${({ theme }) => css`
    @media (max-width: ${theme.mobileWidth}px) {
      font-size: ${theme.mobile.title};
      line-height: ${theme.mobile.title};
    }
  `}
`;

const Splash = styled('div')`
  font-size: ${({ theme }) => theme.fontSize.subtitle};
  line-height: ${({ theme }) => theme.fontSize.subtitle};

  ${({ theme }) => css`
    @media (max-width: ${theme.mobileWidth}px) {
      font-size: ${theme.mobile.subtitle};
      line-height: ${theme.mobile.subtitle};
    }
  `}
`;

const SlurDisplay = ({ slur }) => (
  <div>
    <Splash>{slur === config.name ? 'Welcome to' : 'Your word is'}</Splash>
    <CSSTransitionGroup
      transitionName="example"
      transitionEnterTimeout={500}
      transitionLeaveTimeout={300}
    >
      <Card key={slur}>{slur}</Card>
    </CSSTransitionGroup>

  </div>
);

export default SlurDisplay;
