import React, { useState } from 'react';
import { Popup, Button } from 'semantic-ui-react';
import InfiniteCalendar from 'react-infinite-calendar';
import { css } from 'emotion';
import { formatDate } from '../util/functions';

const noPadding = css`
  padding: 0 !important;
`;

const rounded = css`
  border-radius: 500rem !important;
`;

const SlurDate = ({ value, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSelect = (date) => {
    onChange(date);
    setIsOpen(false);
  }

  const button = (
    <Button
      className={rounded}
      content={formatDate(value)}
      onClick={() => setIsOpen(!isOpen)}
      disabled={disabled}
    />
  );

  return (
    <Popup
      basic
      className={noPadding}
      on="click"
      trigger={button}
      open={isOpen}
    >
      <InfiniteCalendar
        selected={value}
        onSelect={onSelect}
        height={200}
        width={300}
        displayOptions={{
          showHeader: false
        }}
      />
    </Popup>
  )
}

export default SlurDate;