import React, { useState } from 'react';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import SlurPicker from './SlurPicker';
import api from '../api';
import SlurDisplay from './SlurDisplay';
import config from '../config';
import dick from '../images/dick.png';
import twitter from '../images/twitter.svg';
import Plug from './Plug';
import jokes from '../jokeSlurs';
import Cuck from './Cuck';
import Help from './Help';

toast.configure()

const Container = styled.div`
  max-width: ${({ theme }) => theme.width}px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-flow: column;
  height: 100%;
  color: ${({ theme }) => theme.colors.text};
`;

const Logos = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const SlurMachine = () => {
  const [slur, setSlur] = useState(config.name);

  const onResponse = ({ word, attempt, date }) => (
    !word
      ? toast.error(`"${attempt}" ${new Date(date) >= new Date() ? 'is' : 'was'}n't a slur on ${date}.`)
      : setSlur(word)
  );

  const jokeOrRequest = (request, input) => (jokes[input.toLowerCase()]
    ? () => Promise.resolve({ word: jokes[input.toLowerCase()] })
    : request);

  const encodeOrDecode = (date, input, encode) => {
    ReactGA.event({
      category: encode ? 'Encode' : 'Decode',
      action: input,
    });
    const request = jokeOrRequest(encode ? api.encode : api.decode, input);
    return request(date, input)
      .then(onResponse);
  };

  return (
    <Container>
      <Help onClick={(text, config) => () => toast.info(text, config)} />
      <SlurDisplay
        slur={slur}
      />
      <SlurPicker
        onPick={encodeOrDecode}
      />
      {/* Maddox is so shit that I had to hardcode this. */}
      {slur === 'cuck' && <Cuck onDismiss={() => setSlur(config.name)} />}
      <Logos>
        <Plug name="The Dick Show" url="https://www.thedickshow.com" img={dick} />
        <Plug name="Twitter" url="https://twitter.com/slursio" img={twitter} />
      </Logos>
    </Container>
  );
};

export default SlurMachine;
