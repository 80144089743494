import React from 'react';
import { Radio } from 'semantic-ui-react';
import strings from '../strings';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const SwitchText = styled('span')`
  margin-left: 1rem;
  font-size: ${({ theme }) => theme.fontSize.subtitle};

  ${({ theme }) => css`
    @media (max-width: ${theme.mobileWidth}px) {
      font-size: ${theme.mobile.subtitle};
      line-height: ${theme.mobile.subtitle};
    }
  `}
`;

const SlurSwitch = ({ checked, onChange }) => {
  return (
    <Wrapper>
      <Radio 
        toggle
        checked={checked}
        onChange={onChange}
      />
      <SwitchText>{strings[checked ? 'encode' : 'decode']}</SwitchText>
    </Wrapper>
  )
}

export default SlurSwitch;