import React from 'react';
import styled from '@emotion/styled';
import cuck from '../images/cuck.png';
import { toast } from 'react-toastify';

const Wrapper = styled('div')`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  img {
    width: 50vmax;
  }
  animation-name: slidein;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;

  @keyframes slidein {
    from {left: -50vmax;}
    to   {left: 0;}
  }
`;

const Cuck = ({ onDismiss }) => {
  toast("Oh no! Cuck alert!")
  return (
    <Wrapper onClick={onDismiss}>
      <img src={cuck} alt="Cuck!" />
    </Wrapper>
  );
};

export default Cuck;
