import { formatDate } from "./util/functions";

const base = 'https://api.slurs.io';

const api = {
  blos: () => fetch(`${base}/blos`).then(r => r.json()),
  encode: (dateQuery, input) => fetch(`${base}/encode/${input}?date=${formatDate(dateQuery, '+')}`)
    .then(r => r.json()),
  decode: (dateQuery, input) => fetch(`${base}/decode/${input}?date=${formatDate(dateQuery, '+')}`)
    .then(r => r.json()),
};

export default api;
