const theme = {
  width: 900,
  mobileWidth: 800,
  mobile: {
    title: '11vmin',
    subtitle: '4vmin',
    plugSize: '10vmin',
  },
  colors: {
    text: '#FFF',
    primary: '#B18561',
    secondary: '#312F2B',
  },
  fontSize: {
    title: '10vmin',
    subtitle: '2vmin',
    help: '5vmin',
  },
  plugSize: '7vmin',
};

export default theme;