import React, { useState } from 'react';
import styled from '@emotion/styled';
import SlurSearch from './SlurSearch';
import SlurDate from './SlurDate';
import SlurSwitch from './SlurSwitch';

const Card = styled('div')`
  display: flex;
  flex-flow: column;
  padding: 0 1rem;
`;

const Buttons = styled('div')`
  display: flex;
`;

const SlurPicker = ({ onPick }) => {
  const [state, setState] = useState({
    encode: true,
    date: new Date(),
    searchInput: '',
    searchQuery: '',
  })

  const onSubmit = (input, date) => {
    setState({
      ...state,
      date,
      searchInput: input,
      searchQuery: input,
    })
    onPick(date, input, state.encode);
  };

  const onModeChange = (encode) => setState({
    ...state,
    encode,
    searchInput: '',
    searchQuery: ''
  })
  
  return (
    <Card>
      <SlurSwitch 
        checked={state.encode}
        onChange={(_, { checked }) => onModeChange(checked)}
      />
      <Buttons>
        <SlurDate
          value={state.date}
          onChange={newDate => onSubmit(state.searchInput, newDate)}
          disabled={!state.searchInput}
        />
        <SlurSearch
          value={state.searchInput}
          query={state.searchQuery}
          onChange={searchQuery => setState({ ...state, searchQuery })}
          searchMode={state.encode}
          onSelect={result => onSubmit(result, state.date)}
        />
      </Buttons>
    </Card>
  );
};

export default SlurPicker;
